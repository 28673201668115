import React, {Component} from 'react';
import styled from "styled-components";
import {media} from "utils/Media"
import StyledButton from "../shared/StyledButton"

const Content = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;
    max-width: 900px;
    padding: 0 1rem;
`

const BoxWrapper = styled.div`
  @media ${media.sm} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`

const StyledButtonCustom = styled(StyledButton)`
  max-width: 320px;
`

const Box = styled.div`
    width: 100%;
    background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
    padding: 3px;
    margin: 1rem 0;
  @media ${media.sm} {
    width: calc(50% - 1rem);
  }
    
    .helper {
        background: ${props => props.theme.colors.black};
        padding: 1rem;
        position: relative;
        color: ${props => props.theme.colors.white};
        min-height: 180px;
        @media ${media.sm} {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media ${media.xl} {
            min-height: 200px;
        }
        
        h3 {
            color: ${props => props.theme.colors.white};
          min-height: 60px;
        }
    }
`

class TextBlock extends Component {
    render() {
        return (
            <Content className="py-3 py-md-5">

                {/*<h2 className="mb-lg-4">Lorem ipsum</h2>*/}
                <p className="mb-lg-4">The Back to the Future The Musical online education resource provides an invaluable glimpse behind the curtain of this award-winning production. Through worksheets, activities, video and interviews with key members of the production, students can develop their skills while learning all about the creative process, roles in the theatre industry and what it really takes to succeed both onstage and behind-the scenes for a major smash-hit musical.</p>
                {/*<p className="mb-lg-4 black">Ipsum</p>*/}

                <BoxWrapper>
                     <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>Back to the Future Context Deck</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/bttf-context-slide-deck-broadway.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>FAILURE BEFORE SUCCESS DECK</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/bttf-failure-before-success-slide-deck-broadway.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>BACK IN TIME (PRE-SHOW MATERIALS)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/section-1-back-in-time-pre-show-materials.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>SCREEN TO STAGE (POST-SHOW MATERIALS)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/section-2-screen-to-stage-post-show-materials.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>LIVE PERFORMANCE REVIEW STUDENT NOTEBOOK</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/section-3-live-performance-review-student-notebook.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3> ROLES IN THEATRE (PROFESSIONAL PRACTICE)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/pdfs/broadway/section-4-roles-in-theatre-professional-practice.pdf">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>

                    {/* <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>Section 1 - Back in Time (Pre-Show Materials)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>Section 2 - Screen to Stage (Post-Show Materials)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>Section 3 - Live Performance Review Student Notebook</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className="helper">
                            <div className="items">
                                <h3>Section 4 - Roles in Theatre (Professional Practice)</h3>
                                <StyledButtonCustom target="_blank" rel=" noopener" href="/">
                                    Download
                                </StyledButtonCustom>
                            </div>
                        </div>
                    </Box> */}
                </BoxWrapper>

            </Content>

        );
    }
}

export default TextBlock
